.header {
    height: fit-content;
    width: 100vw;
    background-color: var(--background);
    display: flex;
    justify-content: space-between;
    padding: 5px 30px 0 25px;
    position: fixed;
    top: 0;
    z-index: 100;
}

#header-left{
    width: fit-content;
    display: flex;
    justify-content: center;
    font-weight: 300;
    padding-top: 5px;
}

#header-left a {
    border: 1px solid white;
    height: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
}

.header ul {
    width: fit-content;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: white;
    font-weight: 300;
}

.header li {
    padding: 5px;
}

.header a {
    text-decoration: none;
    color: white;
}

.header ul a:hover {
    color: var(--custom-cyan);
    text-decoration: 1px solid underline;
    text-underline-offset: 12px;
}

#resume-button {
    border: 1px solid var(--custom-cyan);
    border-radius: 5px;
    height: fit-content;
    color: var(--custom-cyan);
}

#resume-button:hover {
    background-color: var(--custom-cyan-low-op);
    color: var(--custom-cyan);
}

#resume-link:hover {
    text-decoration: none;
}