.profile-page {
    height: 100vh;
    width: 100vw;
    line-height: 1.4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-info {
    height: fit-content;
    width: 55vw;
    display: flex;
    align-items: flex-end;
}

.profile-info-left h1 {
    font-size: 4.5rem;
    color: white;
    font-weight: 600;
}

.profile-info-left h2 {
    font-size: 2.3rem;
    font-weight: 600;
}

.profile-info-left h3 {

    font-size: 1.9rem;
    color: white;
    font-weight: 200;
}

.profile-info-left p {
    font-size: 1.5rem;
    font-weight: 300;
}

@media screen and (max-width: 520px){
    .profile-info {
        width: 36ch;
    }
}